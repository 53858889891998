import React, { useState } from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';
import Modal from '../elements/Modal';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const FeaturesSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const sectionHeader = {
    title: 'Services & Products',
    paragraph: 'We offer ERP, POS, mobile & desktop apps, AI models, blockchain smart contracts. See some of our products below.'
  };

  const [videoModalActive, setVideomodalactive] = useState(false);

  const openModal = (e) => {
    e.preventDefault();
    setVideomodalactive(true);
  }

  const closeModal = (e) => {
    e.preventDefault();
    setVideomodalactive(false);
  }

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container" id="portfolio">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={splitClasses}>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <h3 className="mt-0 mb-12">
                  Artificial Intelligence
                  </h3>
                  <div className="text-xx text-color-primary fw-600 tt-u mb-8">
                  Tomato Disease Detection
                  </div>
                <p className="m-0">
                  Tomato farmers experience low productivity and loss of revenue due to diseases that can be diagnosed. — We made an artificial intelligent model to diagnose 
                  these diseases and provide remedy for tomato farmers.
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/features-split-image-01.png')}
                  alt="Features split 01"
                  width={528}
                  height={396} />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
              <h3 className="mt-0 mb-12">
                  Blockchain
                  </h3>
                <div className="text-xx text-color-primary fw-600 tt-u mb-8">
                  Property Registry System
                  </div>
                <p className="m-0">
                  Property rights are contested in the courts of Zimbabwe due to corruption and land barons. — We made a property registry system that assists in verifying 
                  ownership of property through NFT's (non-fungible tokens) issued on a blockchain.
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <a
                  data-video="https://youtu.be/MyIb82obaws"
                  href="#0"
                  aria-controls="video-modal"
                  onClick={openModal}>
                  <Image
                    src={require('./../../assets/images/softnet_blockchain.gif')}
                    alt="Features split 02"
                    width={528}
                    height={396} />
                </a>
                <Modal
                  id="video-modal"
                  show={videoModalActive}
                  handleClose={closeModal}
                  video="https://www.youtube.com/embed/MyIb82obaws"
                  videoTag="iframe" 
                />
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
              <h3 className="mt-0 mb-12">
                  Business Management
                  </h3>
                  
                
                <div className="text-xx text-color-primary fw-600 tt-u mb-8">
                  ERP
                </div>
                <p className="m-0">
                  Sustainable businesses are dependant on data to function. — We provide ERP solutions that provide one source of truth for accounting, billing, 
                  project management, payroll, HR, procurement etc. The solutions are deployable in the cloud or on-premise.
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  src={require('./../../assets/images/features-split-image-03.png')}
                  alt="Features split 03"
                  width={528}
                  height={396} />
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;
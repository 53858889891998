import React from 'react';
import classNames from 'classnames';

const FooterNav = ({
  className,
  ...props
}) => {

  const classes = classNames(
    'footer-nav',
    className
  );

  return (
    <nav
      {...props}
      className={classes}
    >
      <ul className="list-reset">
        <li>
          <a href="https://wa.me/+263778726595" target="_blank">+263778726595</a>
        </li>
        <li>
          <a href="mailto:x@softnet.co.zw">x@softnet.co.zw</a>
        </li>
      </ul>
    </nav>
  );
}

export default FooterNav;